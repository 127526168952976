import React from "react";
import Layout from "../../components/layout";

class BasicTerminology extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Basic Terminology</h1>
                <p>
                    It is important that you listen to your Sensei using the Japanese
                    terminology. As you progress through the grades you will become more
                    familiar with the wording. When you reach Shodan (black belt) you
                    should know at least 90% of the terminology below.
                </p>

                <h3>Counting</h3>
                <p>ichi one</p>
                <p>ni two</p>
                <p>san three</p>
                <p>shi (yon) four</p>
                <p>go five</p>
                <p>roku six</p>
                <p>shichi (nana) seven</p>
                <p>hachi eight</p>
                <p>kyu nine</p>
                <p>ju ten</p>
                <p>hyaku one hundred</p>

                <h3>General</h3>
                <p>go hard</p>
                <p>ju soft</p>
                <p>ryu style</p>
                <p>kara empty</p>
                <p>te hand</p>
                <p>sensei teacher</p>
                <p>ki spirit, inner power</p>
                <p>kiai shout used to unite inner power and ki</p>
                <p>dan level</p>
                <p>jodan upper level</p>
                <p>chudan middle level</p>
                <p>gedan lower level</p>
                <p>hidari left</p>
                <p>migi right</p>
                <p>mawate turn</p>
                <p>kaete change</p>
                <p>hito person</p>
                <p>rei bow</p>
                <p>yoi ready</p>
                <p>kamae combative posture</p>
                <p>hajime begin</p>
                <p>yame stop</p>
                <p>hinari twist</p>
                <p>kime focus</p>
                <p>mokuso meditation</p>

                <h3>Stances</h3>
                <p>dachi/tachi stance</p>
                <p>fudo dachi free stance</p>
                <p>heisoku dachi closed foot stance (feet together)</p>
                <p>
                    musubi dachi formal attention stance (heels together, feet at an
                    angle)
                </p>
                <p>heiko dachi parallel stance (feet shoulder width apart)</p>
                <p>
                    hachiji dachi natural stance (feet shoulder width apart, toes slightly
                    pointed out)
                </p>
                <p>kiba dachi horse riding stnace</p>
                <p>shiko dachi straddle leg stance</p>
                <p>
                    sanchin dachi hourglass stance (back toes in line with front heel)
                </p>
                <p>
                    han zenkutsu dachi half front stance (back knee drop down to front
                    toes)
                </p>
                <p>zenkutsu dachi front stance (back knee drops down to front heel)</p>
                <p>kokutsu dachi back stance</p>
                <p>neko ashi dachi cat foot stance</p>
                <p>renoji dachi the letter L stance</p>
                <p>sesan dachi side facing straddle stance</p>

                <h3>Hand Techniques</h3>
                <p>tsuki punch or thrust</p>
                <p>uchi strike</p>
                <p>nakadaka ken middle finger knuckle fist</p>
                <p>ippon ken first finger knuckle fist</p>
                <p>kuma de bear hand</p>
                <p>washi de eagle hand</p>
                <p>empi/hiji elbow</p>
                <p>choku tsuki straight punch</p>
                <p>nihon tsuki double punch</p>
                <p>sanbon tsuki triple punch</p>
                <p>kizame tsuki leading punch, jab</p>
                <p>oi tsuki lunge punch</p>
                <p>gyaku tsuki reverse punch</p>
                <p>ura tsuki short punch (palm side up)</p>
                <p>age tsuki rising punch</p>
                <p>tate tsuki vertical punch</p>
                <p>kagi tsuki short punch</p>
                <p>furi tsuki circular punch</p>
                <p>mawashi tsuki round hook punch</p>
                <p>heiko tsuki parallel punch</p>
                <p>hasami tsuki scissors punch</p>
                <p>yama tsuki mountain punch</p>
                <p>awase/so tsuki u-punch</p>
                <p>nagashi tsuki flowing punch</p>
                <p>shotei/teisho tsuki palm heel thrust</p>
                <p>seiken tsuki fore fist strike</p>
                <p>nukite tsuki finger thrust</p>
                <p>ko uchi bent wrist strike</p>
                <p>ura ken uchi back fist strike</p>
                <p>shuto uchi knife hand srike</p>
                <p>hiji uchi/ate elbow strike/smash</p>
                <p>tettsui uchi bottom fist strike</p>
                <p>shotei/teisho uchi palm heel strike</p>
                <p>tsukame grab</p>

                <h3>Foot Techniques</h3>
                <p>geri kick</p>
                <p>ashi foot/leg</p>
                <p>josokutei ball of the foot</p>
                <p>sokuto foot edge</p>
                <p>kakato heel</p>
                <p>haisoku instep</p>
                <p>tsumasaki tip of the toe</p>
                <p>hiza knee</p>
                <p>mae geri (kaegi) front snap kick</p>
                <p>mae geri (kekomi) front thrust kick</p>
                <p>mawashi geri round house kick</p>
                <p>ushiro geri back thrust kick</p>
                <p>yoko geri (keagi) side snap kick</p>
                <p>yoko geri (kekomi) side thrust kick</p>
                <p>kansetsu geri stamping kick, joint kick</p>
                <p>hiza geri knee kick</p>
                <p>nidan geri double front snap kick (back leg first)</p>
                <p>ren geri double front snap kick (front leg first)</p>
                <p>ashi barai foot sweep</p>
                <p>mae tobi geri jumping front kick</p>
                <p>yoko tobi geri jumping side thrust kick</p>

                <h3>Blocking Techniques</h3>
                <p>uke block</p>
                <p>age uke (jodan) rising block</p>
                <p>chudan uke inside circular block</p>
                <p>chudan uchi uke outside forearm block</p>
                <p>gedan barai downward block</p>
                <p>gedan uchi barai outside downward block (open block)</p>
                <p>hiki uke pulling/grasping block</p>
                <p>kake uke open handed block</p>
                <p>shuto uke knife hand block</p>
                <p>shotei uke palm heel block</p>
                <p>ko uke wrist block</p>
                <p>ura uke back hand block</p>
                <p>uchi uke inside forarm block</p>
                <p>morote uke augmented block (double handed)</p>
                <p>mawashi uke round house block</p>
                <p>tora guchi double handed open-hand circular block</p>
                <p>hari uke archer block</p>
                <p>hiji uke elbow block</p>
                <p>kuri uke downward elbow block</p>
                <p>hiza uke knee block</p>

                <h3>Practice Fighting</h3>
                <p>kumite sparring</p>
                <p>sandan gi basic sparring adapted from Gekisai kata</p>
                <p>
                    sanbon kumite three step sparring (three jodan, three chudan, three
                    gedan)
                </p>
                <p>
                    ippon kumite one point sparring where the attacker defends, then
                    counters after the attack
                </p>
                <p>
                    sandan kumite three step, three level sparring (one jodan, one chudan,
                    one gedan)
                </p>
                <p>
                    sanbon tsuki three step sparring, blocking with one hand against a 3
                    punch combination
                </p>
                <p>kihon ippon basic one step sparring</p>
                <p>jiyu ippon one step sparring from ippon</p>
                <p>randori slow and soft free style with emphasis on technique</p>
                <p>jiyu kumite hard and fast controlled free style fighting</p>

                <h3>Opening Ceremony</h3>
                <p>Head Student: shugo line up</p>
                <p>Head Student: ki o tsuke attention</p>
                <p>Head Student: seiza kneel</p>
                <p>Head Student: mokuso meditation</p>
                <p>Head Student: mokuso yame finish meditation</p>
                <p>Head Student: shomen ni, face front</p>
                <p>Head Student: rei bow</p>
                <p>Head Student: sensei ni face instructor</p>
                <p>Head Student: rei bow</p>
                <p>
                    Everybody: onegai shimasu "will you please?" - In karate "Please teach
                    me Head Student: kiritsu stand up
                </p>

                <h3>Closing Ceremony</h3>
                <p>Head Student: shugo line up</p>
                <p>Head Student: ki o tsuke attention</p>
                <p>Head Student: seiza kneel</p>
                <p>Head Student: mokuso meditation</p>
                <p>Head Student: mokuso yame finish meditation</p>
                <p>Head Student: dojo kun shouted by head student, then by everyone</p>
                <p>Head Student: shinzen ni face shrine/founder</p>
                <p>Head Student: rei bow</p>
                <p>Head Student: sensei ni face instructor</p>
                <p>Head Student: rei bow</p>
                <p>Everybody: arigato gozaimashita "Thank you very much"</p>
                <p>Head Student: otagai ni face each other</p>
                <p>Head Student: rei bow</p>
                <p>Everybody: arigato gozaimashita "Thank you very much"</p>
                <p>Head Student: kiritsu stand up</p>
            </Layout>
        );
    }
}

export default BasicTerminology;
